import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap";

import '../scss/app.scss'

import MainNav from "../components/MainNav/MainNav"
import Footer from "../components/Footer/Footer"
import ContentSideNav from "../components/ContentSideNav/ContentSideNav"

const MainLayout = (props) => {
  const data = useStaticQuery(graphql`
    query {
      categories: allMarkdownRemark(
        limit: 2000
      ) {
        group(field: frontmatter___category) {
          fieldValue
          totalCount
        }
      }
      tags: allMarkdownRemark(
        limit: 2000
      ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)
  const { children, withSideNav } = props;
  let content;
  if (withSideNav) {
    content = <Container>
                <Row>
                  <Col xl="8" py="5" px-md="5">
                    {children}
                  </Col>
                  <Col xl="4" pt="5" sidebar className="bg-light">
                    <ContentSideNav/>
                  </Col>
                </Row>
              </Container>;
  } else {
    content = <Container>
                <Row>
                  <Col md="12">
                    {children}
                  </Col>
                </Row>
              </Container>;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>
      <MainNav config={config}/>
      <Container>
        <Row>
          {content}
        </Row>
      </Container>
      <Footer config={config} />
    </>
  )
}

export default MainLayout
