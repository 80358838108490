import React from "react";
import { Link } from "gatsby";
import styled from "styled-components"

const StyledLink = styled(Link)`
  color: black;
  &:hover {
    cursor: pointer;
    color: #51bcda;
    transition: color 0.25s;
  }
`

const Title = styled.h1`
  font-size: 2.1rem;
  font-weight: 800;
  font-family: "Neuton";
  line-height: 1;
  padding-bottom: 10px;
`

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        {/* Your post list here. */
        postList.map(post => (
          <div>
            <StyledLink to={post.path} key={post.title}>
              <Title>{post.title}</Title>
            </StyledLink>
            <p>{post.excerpt}</p>
            <p><b>Time to read:</b> {post.timeToRead} min(s)</p>
            <Link to={post.path} key={post.title}>Continue Reading</Link>
          </div>
        ))}
      </div>
    );
  }
}

export default PostListing;
