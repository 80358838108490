import React, { Component } from "react";
import styled from "styled-components"

import { Container } from "reactstrap";

const StyledFooter = styled.footer`

`

class Footer extends Component {
  render() {
    const { config } = this.props;
    const { copyright } = config;

    return (
      <StyledFooter className="footer bg-dark">
        <Container>
          <span className="text-white">{copyright}</span>
          <span className="float-right"><a href="#">Back to Top</a></span>
        </Container>
      </StyledFooter>
    );
  }
}

export default Footer;
