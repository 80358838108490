import React from "react";
import { Link } from "gatsby";
import kebabCase from 'lodash/kebabCase'
import styled from "styled-components"

const TagLabel = styled.span`
  margin-bottom: 7px;
  margin-right: 4px;
  font-weight: 700;
  background-color: #fbc658;
  padding: .2em .6em;
  border-radius: 10px;
  color: #fff;
  font-size: .75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 17px;
`

class ArticleTags extends React.Component {
  render() {
    const { tags } = this.props

    return (
      <>
        {tags.map(item => (
          <Link to={`/tags/${kebabCase(item)}/`} className="tags__list-item-link">
            <TagLabel className="tags__list-item">{item}</TagLabel>
          </Link>
        ))}
      </>
    );
  }
}

export default ArticleTags;
