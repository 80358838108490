import React, { Component } from "react";
import { Link, useStaticQuery, graphql } from "gatsby"
import kebabCase from 'lodash/kebabCase'
import capitalize from "lodash/capitalize"
import styled from "styled-components"
import ArticleTags from "../ArticleTags/ArticleTags"

const ListHeader = styled.h3`
  padding: 0;
  font-size: 1.5em;
  font-style: italic;
  margin-bottom: 30px;
`

const List = styled.ul`
  padding: 0;
`

const CategoryListItem = styled.li`
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  list-style: none;
`

const CategoryCount = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  color: #ccc;
`

const ContentSideNav = (props) => {
    const data = useStaticQuery(graphql`
      query {
        categories: allMarkdownRemark(
          limit: 2000
        ) {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
        tags: allMarkdownRemark(
          limit: 2000
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `)

    return (
    <>
        <div className="p-3">
            <ListHeader>Categories</ListHeader>
            <List>
                {data.categories.group.map(item => (
                    <CategoryListItem key={item.fieldValue} className="categories__list-item">
                      <Link to={`/categories/${kebabCase(item.fieldValue)}/`} className="categories__list-item-link">
                          {capitalize(item.fieldValue)}<CategoryCount>({item.totalCount})</CategoryCount>
                      </Link>
                    </CategoryListItem>
                ))}
            </List>
        </div>
        <div className="p-3">
            <ListHeader>Tags</ListHeader>
            <ArticleTags tags={data.tags.group.map(item => item.fieldValue)}/>
        </div>
    </>
    )
}

export default ContentSideNav;
