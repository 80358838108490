import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container } from "reactstrap"
import logo from "../../assets/logo.png"

const StyledNav = styled.nav`
  background-color: #000;
  font-weight: 500;
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  padding: 0;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 1020;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`

const NavContainer = styled(Container)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

const LinkWrapper = styled.div`
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
`

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-right: auto !important;
  margin-bottom: 0;
`

const NavLinkItem = styled.li`
  list-style: none;

  a {
    color: #ffffff;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    margin: 15px 3px;
    text-transform: uppercase;
    display: block;

    &:hover {
      opacity: 1;
    }
  }
`

class MainNav extends Component {
  render() {
    return (
      <StyledNav className="bg-dark">
        <NavContainer>
          <Link to="/">
            <img src={logo} height="75px" alt="Blended Software logo" />
          </Link>
          <LinkWrapper>
            <NavLinks>
              <NavLinkItem className="nav-item">
                <Link to="/" className="nav-link" activeClassName="active">
                  Home
                </Link>
              </NavLinkItem>
              <NavLinkItem className="nav-item">
                <Link
                  to="/articles"
                  className="nav-link"
                  activeClassName="active"
                >
                  Articles
                </Link>
              </NavLinkItem>
            </NavLinks>
          </LinkWrapper>
        </NavContainer>
      </StyledNav>
    )
  }
}

export default MainNav
